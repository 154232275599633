import { useState } from 'react'

//lib
import { MDBCardImage } from 'mdb-react-ui-kit'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

//redux
import { useSelector } from 'react-redux'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'
import LazyImage from '../../../components/common/common.image'

export default function SwiperCarImage({ data, status }) {
  const { width } = useWindowDimensions()
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={0}
      grabCursor={true}
      pagination
      loop={true}
      navigation={width > 991 ? true : false}
      modules={[Navigation, Pagination]}>
      {data.length > 0 &&
        data
          .filter(
            (item) =>
              item.collection_type === 'ready_stock_image' ||
              item.collection_type === 'exterior_image',
          )
          .sort((a, b) => {
            if (a.collection_type === b.collection_type) return 0
            return a.collection_type === 'ready_stock_image' ? -1 : 1
          })
          .map((item, index) => {
            return (
              <SwiperSlide className="carListing-img" key={index}>
                {/* {status === "reserved" &&  <div className='booking-icon'>
                <p>
                BOOKED
                </p>
              </div>} */}
                <LazyImage src={item.url} item={item} alt="Dconcept ready stock car" />
                <div
                  className={
                    status === 'reserved' ? 'carListing-condition2 active' : 'carListing-condition2'
                  }>
                  <label>{status}</label>
                </div>
              </SwiperSlide>
            )
          })}
    </Swiper>
  )
}
