import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Icon } from '@iconify/react'
import useWindowDimensions from '../../components/common/window-dimension'
//assets
import CarLoader2 from './../../assets/json/car-loader.json'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
//lib
import { Swiper, SwiperSlide } from 'swiper/react'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
// import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import { Pannellum } from 'pannellum-react'
import Lottie from 'lottie-react'
import 'swiper/css'
import { Navigation, EffectCreative } from 'swiper'

//components
import CarImage from './component/carImage'
import CarInfoTable from './component/carInfo.table'
import TiktokVideo from './component/tiktokVideo'
import CarSpecification from './component/carSpecification'
import CarBasicInfo2 from './component/carBasicInfo2'
import SamePriceRange from './component/samePriceRange'
import HeaderSecondary from '../../components/header/header-secondary'
import Header from '../../components/header/header-main'
import CarInfoPreorderTable from './component/carInfoPreorderTable'
import ImageResizer from '../../helper/resize.image'

//redux
import { useDispatch, useSelector } from 'react-redux'
import {
  setImageModalShow,
  setCurrentSlides,
  setTotalSlides,
} from '../../redux/reducer/carInfoReducer'
import { getSamePriceRange, getStockInfo, setStockType } from '../../redux/reducer/stockReducer'
import {
  setPdfInteriorStockImg,
  setExteriorStockImg,
  setReportImgData,
  setDisplayImgData,
} from '../../redux/reducer/stockReducer'
import { Skeleton } from 'primereact/skeleton'

//hook
import LazyImage from '../../components/common/common.image'
import OpenCarPdf from './component/openCarPdf'

export default function CarInfo() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigateTo = useNavigate()
  const { isDarkMode } = useSelector((state) => state.home)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [window.location.pathname])

  const { searchData, searchStock, searchPage } = useSelector((state) => state.stock)

  const {
    displayImage,
    stockInfo,
    exteriorStockImg,
    interiorStockImg,
    reportImg,
    pdfInteriorStockImg,
    pdfExteriorStockImg,
    pdfReportStockImg,
    stockType,
    interior360Img,
    exterior360Img,
    stockVideo,
    stock_info_loading,
    pdfInteriorStockImgV2,
    exteriorStockImgV2,
    reportImgDatav2,
    displayImgDatav2,
    pdfDisplayStockImg,
    samePriceRange,
  } = useSelector((state) => state.stock)
  const { width } = useWindowDimensions()

  const allImageArray = []
  // useEffect(() => {
  //   let updatedImages = []
  //   let updatedImages2 = []
  //   let updatedImages3 = []
  //   let updatedImages4 = []

  //   if (!stock_info_loading) {
  //     if (pdfInteriorStockImg && pdfInteriorStockImg.length > 0) {
  //       pdfInteriorStockImg.forEach((image, index, array) => {
  //         const img = new Image()
  //         img.onload = () => {
  //           updatedImages.push({
  //             image_id: image.image_id,
  //             source: image.source,
  //             width: img.naturalWidth,
  //             height: img.naturalHeight,
  //           })
  //           if (updatedImages.length === array.length) {
  //             const sortedImages = updatedImages.sort((a, b) => a.image_id - b.image_id)
  //             dispatch(setPdfInteriorStockImg(sortedImages))
  //           }
  //         }
  //         img.src = image.source
  //       })
  //     }

  //     if (pdfExteriorStockImg && pdfExteriorStockImg.length > 0) {
  //       pdfExteriorStockImg.forEach((image, index, array) => {
  //         const img2 = new Image()
  //         img2.onload = () => {
  //           updatedImages2.push({
  //             image_id: image.image_id,
  //             source: image.source,
  //             width: img2.naturalWidth,
  //             height: img2.naturalHeight,
  //           })
  //           if (updatedImages2.length === array.length) {
  //             const sortedImages2 = updatedImages2.sort((a, b) => a.image_id - b.image_id)
  //             dispatch(setExteriorStockImg(sortedImages2))
  //           }
  //         }
  //         img2.src = image.source
  //       })
  //     }

  //     if (pdfReportStockImg && pdfReportStockImg.length > 0) {
  //       pdfReportStockImg.forEach((image, index, array) => {
  //         const img3 = new Image()
  //         img3.onload = () => {
  //           updatedImages3.push({
  //             image_id: image.image_id,
  //             source: image.source,
  //             width: img3.naturalWidth,
  //             height: img3.naturalHeight,
  //           })
  //           if (updatedImages3.length === array.length) {
  //             const sortedImages3 = updatedImages3.sort((a, b) => a.image_id - b.image_id)
  //             dispatch(setReportImgData(sortedImages3))
  //           }
  //         }
  //         img3.src = image.source
  //       })
  //     }

  //     if (pdfDisplayStockImg && pdfDisplayStockImg.length > 0) {
  //       pdfDisplayStockImg.forEach((image, index, array) => {
  //         const img4 = new Image()
  //         img4.onload = () => {
  //           updatedImages4.push({
  //             image_id: image.image_id,
  //             source: image.source,
  //             width: img4.naturalWidth,
  //             height: img4.naturalHeight,
  //           })
  //           if (updatedImages4.length === array.length) {
  //             const sortedImages4 = updatedImages4.sort((a, b) => a.image_id - b.image_id)
  //             dispatch(setDisplayImgData(sortedImages4))
  //           }
  //         }
  //         img4.src = image.source
  //       })
  //     }
  //   }
  // }, [
  //   stock_info_loading,
  //   pdfInteriorStockImg,
  //   pdfExteriorStockImg,
  //   pdfReportStockImg,
  //   pdfDisplayStockImg,
  // ])
  if (displayImage && displayImage.length > 0) {
    for (var key in displayImage) {
      allImageArray.push(displayImage[key]?.url)
    }
  }

  if (exteriorStockImg && exteriorStockImg.length > 0) {
    for (var key in exteriorStockImg) {
      allImageArray.push(exteriorStockImg[key]?.source)
    }
  }

  if (interiorStockImg && interiorStockImg.length > 0) {
    for (var key in interiorStockImg) {
      allImageArray.push(interiorStockImg[key]?.source)
    }
  }

  if (reportImg && reportImg.length > 0) {
    for (var key in reportImg) {
      allImageArray.push(reportImg[key]?.source)
    }
  }

  useEffect(() => {
    dispatch(setImageModalShow(false))
  }, [])

  useEffect(() => {
    dispatch(getStockInfo({ id: id }))
    dispatch(getSamePriceRange({ id: id }))
  }, [id])

  const checkIsPreorder = stockInfo?.ready_stock_type === 'preorder'

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const toggleLightbox = (index) => {
    setCurrentImageIndex(index)
    setLightboxIsOpen(!lightboxIsOpen)
  }

  const [resizedImageUrl, setResizedImageUrl] = useState('')

  const handleResizeComplete = (newUrl) => {
    setResizedImageUrl(newUrl)
  }

  const [resizedInteriorUrl, setResizedInteriorUrl] = useState('')

  const handleResizeInteriorComplete = (newUrl) => {
    setResizedInteriorUrl(newUrl)
  }
  return (
    <div className="carInfo-mainContainer">
      <header className="carInfo-header-container">
        <Header />
      </header>
      <MDBContainer className="p-0">
        <HeaderSecondary
          className={'secondary-header'}
          handleClick={() => {
            const urlPathName = stockInfo?.ready_stock_type

            if (urlPathName == 'preorder') {
              if (searchPage !== 1 && searchData !== '') {
                navigateTo(`/preorder?search=${searchData}&page=${searchPage}`)
              } else if (searchData !== '') {
                navigateTo(`/preorder?search=${searchData}`)
              } else if (searchPage !== 1) {
                navigateTo(`/preorder?page=${searchPage}`)
              } else {
                navigateTo(`/preorder`)
              }
              dispatch(setStockType(stockType))
            } else {
              if (searchPage !== 1 && searchStock !== '') {
                navigateTo(`/stock?search=${searchStock}&page=${searchPage}`)
              } else if (searchStock !== '') {
                navigateTo(`/stock?search=${searchStock}`)
              } else if (searchPage !== 1) {
                navigateTo(`/stock?page=${searchPage}`)
              } else {
                navigateTo(`/stock`)
              }
              dispatch(setStockType(stockType))
            }
          }}
          title={
            <div className="text">
              CODE
              <span className="secondaryText" style={{ color: '#FBB24B' }}>
                {stockInfo.code}
              </span>
            </div>
          }
        />
        <div className="carInfo-image-div">
          {!stock_info_loading ? (
            <Swiper
              className="mySwiper"
              navigation={true}
              effect={'creative'}
              grabCursor={true}
              creativeEffect={{
                prev: {
                  shadow: true,
                  translate: [0, 0, -400],
                },
                next: {
                  translate: ['100%', 0, 0],
                },
              }}
              modules={[Navigation, EffectCreative]}
              spaceBetween={0}
              slidesPerView={1}
              onSlideChange={(s) => dispatch(setCurrentSlides(s.activeIndex + 1))}
              onSwiper={(swiper) => dispatch(setTotalSlides(swiper.slides.length))}>
              <div className="pageCount">
                {/* <p>
                  {currentSlides}/{totalSlides}
                </p> */}
              </div>
              {allImageArray.map((imageUrl, index) => (
                <SwiperSlide key={index} className="carInfo-swiper-container">
                  <div className="carInfo-display">
                    <LazyImage
                      style={{ cursor: 'pointer' }}
                      className="carInfo-image"
                      src={imageUrl}
                      alt="stock-display-img"
                      onClick={() => {
                        toggleLightbox(index)
                      }}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <>
              <div
                className="overlay-img"
                style={{
                  height:
                    width > 1200
                      ? '500px'
                      : width > 991
                      ? '450px'
                      : width > 768
                      ? '400px'
                      : '250px',
                }}></div>
              <div className="overlay-img-div d-flex justify-content-center">
                {/* <LazyLoadImage src={CarLoader} width="100%"/> */}
                <Lottie animationData={CarLoader2} style={{ width: '100%' }} />
              </div>
            </>
          )}
        </div>
        <div style={{ position: 'relative', top: '-1.5em', zIndex: 1 }}>
          {/* <CarBasicInfo data={stockInfo} loading={stock_info_loading} /> */}
          <CarBasicInfo2 data={stockInfo} loading={stock_info_loading} />

          <MDBContainer className="carInfo-details-container">
            <MDBCol>
              <div className="carInfo-details-col">
                {!stock_info_loading ? (
                  <div className="carInfo-details-carName">{stockInfo.car_model_name}</div>
                ) : (
                  <Skeleton width="20em" height="15px" className="mt-2 " />
                )}
                {!checkIsPreorder && (
                  <MDBRow>
                    <MDBCol className="col-8 ">
                      {!stock_info_loading && stockInfo.ready_stock_type && (
                        <div className="vicit-location-div">
                          <label className="_label --stock">
                            {' '}
                            <Icon
                              icon="tabler:report-search"
                              color={isDarkMode ? '#D1D5DB' : '#24262D'}
                              width={'18px'}
                              height={'18px'}
                            />{' '}
                            &nbsp; <span className="stock"> {stockInfo.ready_stock_type} </span>{' '}
                          </label>
                        </div>
                      )}
                      {!stock_info_loading ? (
                        <div className="vicit-location-div">
                          <label className="_label">
                            {' '}
                            <Icon
                              icon="mdi:car-location"
                              color={isDarkMode ? '#D1D5DB' : '#24262D'}
                              width={'18px'}
                              height={'18px'}
                            />{' '}
                            &nbsp;
                            {stockInfo.visit_location !== null ? stockInfo.visit_location : '-'}
                          </label>
                        </div>
                      ) : (
                        <Skeleton width="20em" height="15px" className="mt-3 mb-0" />
                      )}
                    </MDBCol>
                    <MDBCol
                      style={{ alignItems: 'center', display: 'flex', justifyContent: 'end' }}>
                      {!stock_info_loading && stockInfo.car_detail?.length > 0 ? (
                        <>
                          <a
                            href={stockInfo.car_detail[0].url}
                            target="_blank"
                            download={`${stockInfo.brand_name}_${stockInfo.car_model_name}.pdf`}>
                            <Icon
                              icon="material-symbols:sim-card-download"
                              color="#ac4343"
                              height="22px"
                            />
                          </a>
                        </>
                      ) : (
                        ''
                      )}
                    </MDBCol>
                  </MDBRow>
                )}
                <div className="carInfo-details-location"></div>

                {/* <PDFViewer style={{ width: '100%', height: '100vh' }}>
                  <OpenCarPdf
                    data={stockInfo}
                    displayImg={pdfDisplayStockImg}
                    exteriorImg={pdfExteriorStockImg}
                    interiorImg={pdfInteriorStockImg}
                    reportImg={pdfReportStockImg}
                  />
                </PDFViewer> */}

                {stockInfo.car_infos && (
                  <CarInfoTable
                    data={stockInfo.car_infos}
                    price={stockInfo.asking_price}
                    loading={stock_info_loading}
                  />
                )}
                {stockInfo.properties && checkIsPreorder && (
                  <CarInfoPreorderTable
                    data={stockInfo.properties}
                    price={stockInfo.asking_price}
                    loading={stock_info_loading}
                  />
                )}
                <CarSpecification data={stockInfo.specification} loading={stock_info_loading} />
                {!stock_info_loading && exterior360Img && exterior360Img.length > 0 ? (
                  <div className="carInfo-360">
                    <div className="carInfo-title">Experience 360 Exterior Look</div>

                    <div className="carInfo-360-image">
                      <div
                        className="overlay-360"
                        onClick={() => {
                          if (resizedImageUrl !== '') {
                            localStorage.removeItem('degree_exterior')
                            localStorage.setItem(
                              'degree_exterior',
                              JSON.stringify({
                                url: stockInfo?.degree360_exterior_video,
                                from: window.location.pathname,
                              }),
                            )
                            navigateTo('/view/exterior_look', { state: resizedImageUrl })
                          }
                        }}></div>

                      {resizedImageUrl !== '' && (
                        <Icon
                          icon="tabler:360-view"
                          className="icon-360"
                          width={50}
                          color="#D1D5DB"
                          // onClick={() =>
                          //   navigateTo('/view/exterior_look', { state: resizedImageUrl })
                          // }
                        />
                      )}

                      {resizedImageUrl !== '' ? (
                        <Pannellum
                          width="100%"
                          height="160px"
                          image={resizedImageUrl}
                          pitch={-10}
                          yaw={360}
                          hfov={100}
                          autoLoad
                          showFullscreenCtrl={false}
                          showZoomCtrl={false}></Pannellum>
                      ) : (
                        <Skeleton width="100%" height="142px" className="mb-3" />
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {stockInfo?.degree360_exterior_video && (
                  <ImageResizer
                    imageUrl={stockInfo?.degree360_exterior_video}
                    onResizeComplete={handleResizeComplete}
                  />
                )}
                {stockInfo?.degree360_interior_video && (
                  <ImageResizer
                    imageUrl={stockInfo?.degree360_interior_video}
                    onResizeComplete={handleResizeInteriorComplete}
                  />
                )}
                {!stock_info_loading && interior360Img && interior360Img.length > 0 ? (
                  <div className="carInfo-360">
                    <div className="carInfo-title">Experience 360 Interior Look</div>
                    <div className="carInfo-360-image">
                      <div
                        className="overlay-360"
                        onClick={() => {
                          if (resizedInteriorUrl !== '') {
                            localStorage.removeItem('degree_interior')
                            localStorage.setItem(
                              'degree_interior',
                              JSON.stringify({
                                url: stockInfo?.degree360_interior_video,
                                from: window.location.pathname,
                              }),
                            )
                            navigateTo('/view/interior_look', { state: resizedInteriorUrl })
                          }
                        }}></div>
                      {resizedInteriorUrl !== '' && (
                        <Icon
                          icon="tabler:360-view"
                          className="icon-360"
                          width={50}
                          color="#D1D5DB"
                          // onClick={() =>
                          //   navigateTo('/view/interior_look', { state: resizedInteriorUrl })
                          // }
                        />
                      )}
                      {resizedInteriorUrl !== '' ? (
                        <Pannellum
                          width="100%"
                          height="160px"
                          image={resizedInteriorUrl}
                          pitch={-10}
                          yaw={360}
                          hfov={100}
                          autoLoad
                          showFullscreenCtrl={false}
                          showZoomCtrl={false}
                        />
                      ) : (
                        <Skeleton width="100%" height="142px" className="mb-3" />
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <TiktokVideo data={stockVideo} loading={stock_info_loading} />
              </div>
            </MDBCol>
            <MDBCol>
              <div className="carInfo-details-col">
                {!stock_info_loading ? (
                  <>
                    {exteriorStockImg && exteriorStockImg.length > 0 && (
                      <CarImage title={'Exterior Look'} data={exteriorStockImg} />
                    )}
                    {interiorStockImg && interiorStockImg.length > 0 && (
                      <CarImage title={'Interior Look'} data={interiorStockImg} />
                    )}
                    {reportImg && reportImg.length > 0 && (
                      <CarImage title={'Report'} data={reportImg} />
                    )}
                  </>
                ) : (
                  Array.from({ length: 1 }, (_, index) => (
                    <MDBContainer key={index}>
                      <div className="carInfo-title">
                        <Skeleton width="20em" height="15px" className="mb-3" />
                      </div>
                      <MDBContainer className="carInfo-look-image">
                        <div className="gap-3 d-flex">
                          <Skeleton width="100%" height="150px" className="mt-3 ms-2" />
                          <Skeleton width="100%" height="150px" className="mt-3 ms-2" />
                        </div>
                        <div className="gap-3 d-flex">
                          <Skeleton width="100%" height="150px" className="mt-3 ms-2" />
                          <Skeleton width="100%" height="150px" className="mt-3 ms-2" />
                        </div>
                      </MDBContainer>
                    </MDBContainer>
                  ))
                )}
              </div>
            </MDBCol>
            {/* <Galleria
              ref={galleria2}
              value={allImageArray}
              numVisible={9}
              style={width > 850 ? { maxWidth: '50%' } : { maxWidth: '100%' }}
              circular
              fullScreen
              showItemNavigators
              showThumbnails={false}
              item={itemTemplate}
              thumbnail={thumbnailTemplate}
            /> */}
            {lightboxIsOpen && (
              <Lightbox
                mainSrc={allImageArray[currentImageIndex]}
                nextSrc={allImageArray[(currentImageIndex + 1) % allImageArray.length]}
                prevSrc={
                  allImageArray[
                    (currentImageIndex + allImageArray.length - 1) % allImageArray.length
                  ]
                }
                onCloseRequest={() => setLightboxIsOpen(false)}
                onMovePrevRequest={() =>
                  setCurrentImageIndex(
                    (currentImageIndex + allImageArray.length - 1) % allImageArray.length,
                  )
                }
                onMoveNextRequest={() =>
                  setCurrentImageIndex((currentImageIndex + 1) % allImageArray.length)
                }
              />
            )}
          </MDBContainer>
        </div>
      </MDBContainer>
      {!stock_info_loading && samePriceRange?.length > 0 && (
        <SamePriceRange title={'Same Price Range'} data={samePriceRange} />
      )}
    </div>
  )
}
