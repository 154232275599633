import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

//lib
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit'

//component
import Header from '../../components/header/header-main'
import Panoroma from './component/panoroma'
import { useDispatch, useSelector } from 'react-redux'
import { setDegreeBase64 } from '../../redux/reducer/carInfoReducer'
import ImageResizer from '../../helper/resize.image'

export default function ViewPanaroma({ state }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const [loading, setLoading] = useState(true)
  const { degreeBase64 } = useSelector((state) => state.carInfo)

  const degreeIntStorageData = localStorage.getItem('degree_interior')
  const degreeExtStorageData = localStorage.getItem('degree_exterior')

  const degreeData = JSON.parse(
    id === 'exterior_look' ? degreeExtStorageData : degreeIntStorageData,
  )

  const handleResizeInteriorComplete = (newUrl) => {
    dispatch(setDegreeBase64(newUrl))
    setLoading(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <MDBContainer className="common-main-container view-360-container" breakpoint="none">
      <Header />
      <MDBContainer className="header-360-container">
        <MDBBtn
          className="back-btn"
          onClick={() => {
            navigate(degreeData.from)
          }}>
          BACK
        </MDBBtn>
      </MDBContainer>
      <ImageResizer imageUrl={degreeData.url} onResizeComplete={handleResizeInteriorComplete} />
      {!loading && <Panoroma state={degreeBase64} />}
    </MDBContainer>
  )
}
