import Marquee from 'react-fast-marquee'
import { Icon } from '@iconify/react'

export default function Announcement({ data }) {
  return (
    <>
      {/* <Ticker
        speed={7}
        direction="toLeft"
        mode="await"
        offset={200}
        move={true}
        className="common-ticker">
        {() => (
          <p>
            <Icon icon="mdi:announcement-outline" width="20" color="#9091A0" />
            &nbsp;&nbsp;{data[0]?.title ? data[0].title : ''}
            {data[0]?.description ? ': ' : ''}
            {data[0]?.description ? data[0].description : ''}
          </p>
        )}
      </Ticker> */}
      <Marquee speed={50} gradient={false}>
        <p>
          <Icon icon="mdi:announcement-outline" width="20" color="#9091A0" />
          &nbsp;&nbsp;{data[0]?.title ? data[0].title : ''}
          {data[0]?.description ? ': ' : ''}
          {data[0]?.description ? data[0].description : ''}
        </p>
      </Marquee>
    </>
  )
}
