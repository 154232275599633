import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Sedan, Suv, Convertible, Wagon, Hatchback, Coupe, Mpv } from '../../assets/images'

import apiServices from '../../services/api.services'

export const stockDetailListing = createAsyncThunk(
  'get/stockDetailListing',
  async (payload, thunkAPI) => {
    try {
      const response = await apiServices.getStockDetailListing(payload)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getDStock = createAsyncThunk('getDStock', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getDStock(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getStockList = createAsyncThunk('get/stock_list', async (payload) => {
  const response = await apiServices.getStockList(payload)
  return response.data
})

export const getStockInfo = createAsyncThunk('get/stock_info', async (payload) => {
  const response = await apiServices.getStockInfo(payload)
  return response.data
})

export const getSamePriceRange = createAsyncThunk('get/stock_same_price_range', async (payload) => {
  const response = await apiServices.getSamePriceRange(payload)
  return response.data
})

export const getFilterOption = createAsyncThunk('get/filter_option_listing', async (payload) => {
  const response = await apiServices.getFilterOption(payload)
  return response.data
})

const currentYear = new Date().getFullYear()

const initialState = {
  totalListingValue: {},
  start: 0,
  length: 10,
  loading: false,
  stockLoading: false,
  ownerLoading: false,

  dummyData: [],
  showBrandList: false,
  showBodyTypeList: false,
  showCarYearList: false,
  showCarPriceList: false,
  showSortByList: false,
  showMobileFilterList: false,
  carBodyTypeListing: [
    {
      icon: Sedan,
      title: 'Sedan',
    },
    {
      icon: Convertible,
      title: 'Convertible',
    },
    {
      icon: Coupe,
      title: 'Coupe',
    },
    {
      icon: Hatchback,
      title: 'Hatchback',
    },
    {
      icon: Mpv,
      title: 'MPV',
    },
    {
      icon: Suv,
      title: 'SUV',
    },
    {
      icon: Wagon,
      title: 'Wagon',
    },
  ],
  stockType: 'D-Stock',
  searchData: '',
  searchStock: '',
  carList: [],
  last_seen_listing: [],
  stockList: [],
  homeDStockList: [],
  homeDOwnerList: [],
  dStockList: [],
  directOwnerList: [],
  stockInfo: {},
  interiorStockImg: [],
  exteriorStockImg: [],
  reportImg: [],
  interior360Img: [],
  exterior360Img: [],
  displayImage: [],
  stockVideo: [],
  availableBodyTypes: [],
  availableCarBrands: [],
  availableCarYears: [],
  availableCarModels: [],
  searchKeywords: [],

  carBodyTypeFilter: [],
  bodyTypeFilterData: [],

  carBrandFilter: [],
  brandFilterData: [],

  carYearFilter: [1990, currentYear],
  yearFilterData: [1990, currentYear],

  carModelFilter: [],
  modelFilterData: [],

  carPriceFilter: [50000, 5000000],
  priceFilterData: [50000, 5000000],

  sortByFilter: [],
  sortBySequence: [],

  stockTotalRecord: 0,
  ownerTotalRecord: 0,

  searchPage: 1,
  displayBrandModelFilter: [],
  displayBrandModelData: [],
  displayText: [],
  originalCarBrands: [],
  pdfInteriorStockImgV2: [],
  exteriorStockImgV2: [],
  reportImgDatav2: [],
  displayImgDatav2: [],

  hasMore: false,
  emptyData: false,
  stockData: [],
  ownerData: [],
  samePriceRange: [],
}

const stockSlice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    setStart: (state, action) => {
      state.start = action.payload
    },
    setLength: (state, action) => {
      state.length = action.payload
    },

    setStockTotalRecord: (state, action) => {
      state.stockTotalRecord = action.payload
    },
    setOwnerTotalRecord: (state, action) => {
      state.ownerTotalRecord = action.payload
    },

    // setLoading: (state, action) => {
    //   state.loading = action.payload
    // },

    setStockLoading: (state, action) => {
      state.stockLoading = action.payload
    },
    setOwnerLoading: (state, action) => {
      state.ownerLoading = action.payload
    },

    setHasMore: (state, action) => {
      state.hasMore = action.payload
    },
    setEmptyData: (state, action) => {
      state.emptyData = action.payload
    },

    setStockData: (state, action) => {
      state.stockData = action.payload
    },
    setOwnerData: (state, action) => {
      state.ownerData = action.payload
    },

    setShowBrandList: (state, action) => {
      state.showBrandList = action.payload
    },
    setShowBodyTypeList: (state, action) => {
      state.showBodyTypeList = action.payload
    },
    setShowCarYearList: (state, action) => {
      state.showCarYearList = action.payload
    },
    setShowCarModelList: (state, action) => {
      state.showCarModelList = action.payload
    },
    setShowCarPriceList: (state, action) => {
      state.showCarPriceList = action.payload
    },
    setShowSortByList: (state, action) => {
      state.showSortByList = action.payload
    },

    setShowMobileFilterList: (state, action) => {
      state.showMobileFilterList = action.payload
    },
    setSearchStock: (state, action) => {
      state.searchStock = action.payload
    },
    setSearchData: (state, action) => {
      state.searchData = action.payload
    },
    setSearchPage: (state, action) => {
      state.searchPage = action.payload
    },
    setStockType: (state, action) => {
      state.stockType = action.payload
    },
    setCarBodyTypeFilter: (state, action) => {
      state.carBodyTypeFilter = action.payload
    },
    setBodyTypeFilterData: (state, action) => {
      state.bodyTypeFilterData = action.payload
    },
    setCarBrandFilter: (state, action) => {
      state.carBrandFilter = action.payload
    },
    setBrandFilterData: (state, action) => {
      state.brandFilterData = action.payload
    },
    setCarModelFilter: (state, action) => {
      state.carModelFilter = action.payload
    },
    setModelFilterData: (state, action) => {
      state.modelFilterData = action.payload
    },
    setCarYearFilter: (state, action) => {
      state.carYearFilter = action.payload
    },
    setYearFilterData: (state, action) => {
      state.yearFilterData = action.payload
    },
    setCarPriceFilter: (state, action) => {
      state.carPriceFilter = action.payload
    },
    setPriceFilterData: (state, action) => {
      state.priceFilterData = action.payload
    },
    setSortByFilter: (state, action) => {
      state.sortByFilter = action.payload
    },
    setSortBySequence: (state, action) => {
      state.sortBySequence = action.payload
    },

    setDisplayBrandModelFilter: (state, action) => {
      state.displayBrandModelFilter = action.payload
    },
    setDisplayBrandModelData: (state, action) => {
      state.displayBrandModelData = action.payload
    },
    updateDisplayText: (state, action) => {
      state.displayText = action.payload
    },
    setPdfInteriorStockImg: (state, action) => {
      state.pdfInteriorStockImgV2 = action.payload
    },
    setExteriorStockImg: (state, action) => {
      state.exteriorStockImgV2 = action.payload
    },
    setReportImgData: (state, action) => {
      state.reportImgDatav2 = action.payload
    },
    setDisplayImgData: (state, action) => {
      state.displayImgDatav2 = action.payload
    },
  },
  extraReducers: {
    [getFilterOption.pending]: (state, action) => {
      state.filterLoading = true
    },
    [getFilterOption.fulfilled]: (state, action) => {
      state.filterLoading = false
      state.availableBodyTypes = action.payload.availableBodyTypes

      const availableCarBrandData = action.payload.availableCarBrands

      const sortedCarBrandData = availableCarBrandData.sort((a, b) => {
        if (a.brand_name < b.brand_name) return -1
        if (a.brand_name > b.brand_name) return 1
        return 0
      })

      const availableCarBrandNewData = sortedCarBrandData.reduce((acc, item) => {
        const key = item.brand_name.charAt(0).toUpperCase()
        if (!acc[key]) acc[key] = []
        acc[key].push(item)
        return acc
      }, {})

      state.availableCarBrands = availableCarBrandNewData
      state.originalCarBrands = availableCarBrandData
    },
    [getFilterOption.rejected]: (state, action) => {
      state.filterLoading = true
    },
    [stockDetailListing.fulfilled]: (state, action) => {
      state.totalListingValue = action.payload.data.totalCarBody
      // state.availableBodyTypes = action.payload.data.availableBodyTypes
      state.searchKeywords = action.payload.data.searchKeywords

      // const availableCarBrandData = action.payload.data.availableCarBrands

      // const sortedCarBrandData = availableCarBrandData.sort((a, b) => {
      //   if (a.brand_name < b.brand_name) return -1
      //   if (a.brand_name > b.brand_name) return 1
      //   return 0
      // })

      // const availableCarBrandNewData = sortedCarBrandData.reduce((acc, item) => {
      //   const key = item.brand_name.charAt(0).toUpperCase()
      //   if (!acc[key]) acc[key] = []
      //   acc[key].push(item)
      //   return acc
      // }, {})

      // state.availableCarBrands = availableCarBrandNewData
      // state.originalCarBrands = availableCarBrandData

      state.availableCarYears = action.payload.data.availableCarYears
      state.availableCarModels = action.payload.data.availableCarModel
    },
    [getDStock.pending]: (state, action) => {},
    [getDStock.fulfilled]: (state, action) => {
      state.carList = action.payload.data
    },
    [getStockList.pending]: (state, action) => {
      state.stock_list_loading = true
    },
    [getStockList.fulfilled]: (state, action) => {
      state.stock_list_loading = false

      const stockData = action.payload.aaData
      const sortedStockData = action.payload.aaData.sort((a, b) => {
        if (a.car_bodies_name < b.car_bodies_name) return -1
        if (a.car_bodies_name > b.car_bodies_name) return 1
        return 0
      })

      const homeDStockData = stockData.filter((item) => item.ready_stock_type === 'D-Stock')
      const homeDOwnerData = stockData.filter((item) => item.ready_stock_type === 'Direct Owner')

      const dStockData = sortedStockData.filter((item) => item.ready_stock_type === 'D-Stock')
      const directOwnerData = sortedStockData.filter(
        (item) => item.ready_stock_type === 'Direct Owner',
      )

      const groupedDStockData = dStockData.reduce((acc, item) => {
        const key = item.car_bodies_name
        if (!acc[key]) acc[key] = []
        acc[key].push(item)
        return acc
      }, {})

      const groupedDOwnerData = directOwnerData.reduce((acc, item) => {
        const key = item.car_bodies_name
        if (!acc[key]) acc[key] = []
        acc[key].push(item)
        return acc
      }, {})

      state.stockList = stockData
      state.homeDStockList = homeDStockData
      state.homeDOwnerList = homeDOwnerData
      state.dStockList = groupedDStockData
      state.directOwnerList = groupedDOwnerData
    },

    [getStockInfo.pending]: (state, action) => {
      state.stock_info_loading = true
    },
    [getStockInfo.fulfilled]: (state, action) => {
      state.stock_info_loading = false
      state.stockInfo = action.payload

      const stockMedias = action.payload.medias
      const displayImage = stockMedias.filter((item) => item.image_type === 'ready_stock_image')
      const exteriorImgData = stockMedias.filter((item) => item.image_type === 'exterior_image')
      const interiorImgData = stockMedias.filter((item) => item.image_type === 'interior_image')
      const reportImgData = stockMedias.filter((item) => item.image_type === 'ready_stock_report')
      const interior360Degree = stockMedias.filter(
        (item) => item.image_type === 'degree360_interior_video',
      )
      const exterior360Degree = stockMedias.filter(
        (item) => item.image_type === 'degree360_exterior_video',
      )
      const stockVideo = stockMedias.filter((item) => item.image_type === 'video')
      state.displayImage = displayImage.map((obj) => {
        return obj
      })

      state.exteriorStockImg = exteriorImgData.map((obj) => {
        // console.log('asd ', obj)

        const sourceImage1 = {}
        sourceImage1['source'] = obj.url
          .replace(/\ /g, '%20')
          .replace(/\(/g, '%28')
          .replace(/\)/g, '%29')
        return sourceImage1
      })

      state.interiorStockImg = interiorImgData.map((obj) => {
        const sourceImage2 = {}
        sourceImage2['source'] = obj.url
          .replace(/\ /g, '%20')
          .replace(/\(/g, '%28')
          .replace(/\)/g, '%29')
        return sourceImage2
      })

      state.reportImg = reportImgData.map((obj) => {
        const sourceImage8 = {}
        sourceImage8['source'] = obj.url
          .replace(/\ /g, '%20')
          .replace(/\(/g, '%28')
          .replace(/\)/g, '%29')
        return sourceImage8
      })

      state.interior360Img = interior360Degree.map((obj) => {
        const sourceImage3 = {}
        sourceImage3['source'] = obj.url
        return sourceImage3
      })

      state.exterior360Img = exterior360Degree.map((obj) => {
        const sourceImage4 = {}
        sourceImage4['source'] = obj.url
        return sourceImage4
      })

      state.stockVideo = stockVideo.map((obj) => {
        const sourceImage5 = {}
        sourceImage5['source'] = obj.url
        return sourceImage5
      })

      state.pdfInteriorStockImg = interiorImgData.map((obj) => {
        const urlImage = obj.api_url
          .replace(/\ /g, '%20')
          .replace(/\(/g, '%28')
          .replace(/\)/g, '%29')
        const sourceImage6 = {
          source: urlImage,
          image_id: obj.image_id,
        }
        return sourceImage6
      })

      state.pdfExteriorStockImg = exteriorImgData.map((obj) => {
        const urlImage = obj.api_url
          .replace(/\ /g, '%20')
          .replace(/\(/g, '%28')
          .replace(/\)/g, '%29')
        const sourceImage7 = {
          source: urlImage,
          image_id: obj.image_id,
        }

        return sourceImage7
      })

      state.pdfReportStockImg = reportImgData.map((obj) => {
        const urlImage = obj.api_url
          .replace(/\ /g, '%20')
          .replace(/\(/g, '%28')
          .replace(/\)/g, '%29')
        const sourceImage8 = {
          source: urlImage,
          image_id: obj.image_id,
        }
        return sourceImage8
      })

      state.pdfDisplayStockImg = displayImage.map((obj) => {
        const urlImage = obj.api_url
          .replace(/\ /g, '%20')
          .replace(/\(/g, '%28')
          .replace(/\)/g, '%29')
        const sourceImage9 = {
          source: urlImage,
          image_id: obj.image_id,
        }

        return sourceImage9
      })
    },
    [getSamePriceRange.pending]: (state, action) => {
      state.samePriceLoading = true
    },
    [getSamePriceRange.fulfilled]: (state, action) => {
      state.samePriceLoading = false
      state.samePriceRange = action.payload
    },
    [getSamePriceRange.rejected]: (state, action) => {
      state.samePriceLoading = true
    },
  },
})

export const {
  setStart,
  setLength,
  // setLoading,
  setStockLoading,
  setOwnerLoading,
  // setTotalRecord,
  setStockTotalRecord,
  setOwnerTotalRecord,
  setShowBrandList,
  setShowBodyTypeList,
  setShowCarYearList,
  setShowCarModelList,
  setShowCarPriceList,
  setShowSortByList,
  setShowMobileFilterList,
  setStockType,
  setCarBodyTypeFilter,
  setBodyTypeFilterData,
  setCarBrandFilter,
  setBrandFilterData,
  setCarModelFilter,
  setModelFilterData,
  setCarYearFilter,
  setYearFilterData,
  setCarPriceFilter,
  setPriceFilterData,
  setSortByFilter,
  setSortBySequence,
  setSearchData,
  setSearchPage,
  setDisplayBrandModelFilter,
  setDisplayBrandModelData,
  updateDisplayText,
  setPdfInteriorStockImg,
  setExteriorStockImg,
  setReportImgData,
  setDisplayImgData,
  setSearchStock,
  setHasMore,
  setEmptyData,
  setStockData,
  setOwnerData,
} = stockSlice.actions
const { reducer } = stockSlice
export default reducer
